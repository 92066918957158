@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
  --bg-primary: #322a5b;
  --bg-primary-hover: #26223c;
  --bg-secundary: #f7f6ff;
  --text-color: #616163;
  --font-family: 'Chivo', sans-serif;
}
.form-control {
  margin: 0px;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	border-radius: 2px;
	background-color: #f5f5f5;
}
::-webkit-scrollbar
{
	width: 12px;
	background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb
{
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
	background-color: var(--bg-primary);
}

.accordion-button:not(.collapsed) {
  color: white;
  background: var(--bg-primary);
}
.accordion-button:not(.collapsed)::after {
  background-image: url(data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>);
}
body {
  margin: 0;
  font-family: 'Chivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
  align-content: center;
}

h1 {
  font-size: 2rem;
}

p, ul, li {
  font-size: .9rem;
  line-height: 1.2;
}

a {
  color: var(--bg-primary);
  font-weight: 600;
}