.bg-lines {
    background-image: url('../public/img/bg-lines.svg');
    background-repeat: repeat;
    background-position: center center;
}
.btn-custom {
    background: var(--bg-primary);
    border-color: var(--bg-primary);
}
.btn-custom:hover, .btn-custom:focus, .btn-custom:active{
    background: var(--bg-primary-hover);
    border-color: var(--bg-primary-hover);
}
.cortar-texto {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
button.btn-custom p {
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}
button.btn-custom svg {
    margin: 0px 6px;
}
