.container-loading {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-loading .loading > p {
    margin-bottom: 0px;
    margin-left: 10px;
}
.container-login > .row > div.content-dashboard {
    max-height: 100vh;
    overflow: auto;
}
.table-content .thead {
    padding: 10px;
    background: #cdcdcd;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
}

.table-content .thead .border-right {
    border-right: 1px solid var(--bs-body-color);
}

.table-content .thead .border-right:last-child {
    border: none;
}
.table-content {
    margin-bottom: 50px;
}
.table-content .tbody {
    margin-top: 20px;
}

.table-content .tbody p {
    margin-bottom: 0px;
    width: 100%;
}

.table-content .tbody > div {
    padding: 10px;
}

.table-content .tbody > div:nth-of-type(odd) {
    background: #ededed;
}
.table-content .tbody .container-fluid > div > div {
    display: flex;
    align-items: center;
}
.table-content .tbody p.estado > span {
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 8px;
    background: white;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    display: block;
    text-align: center;
}
.table-content .tbody p.estado > span.rechazado {
    background: red;
    color: #4e2828;
    border-color: red;
}

.table-content .tbody p.estado > span.evaluadores.asignados {
    background: #00e5ff;
    border-color: #00e5ff;
    color: #155e5a;
}
.table-content .tbody p.estado > span.evaluación {
    background: #2bd22b;
    border-color: #2bd22b;
}
.table-content .tbody p.estado > span.no.evaluado {
    background: #ebae26;
    border-color: #ebae26;
}
.table-content .thead .border-right a {
    font-weight: 500;
    color: var(--bs-body-color);
    text-decoration: none;
}   
input.inputSearch.form-control {
    width: 300px;
}

.detalles-investigacion .puntuacion {
    background: white;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    padding: 20px;
    border-radius: 8px;
}

.detalles-investigacion .puntuacion .total p.titlePuntos {
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0px;
}

.detalles-investigacion .puntuacion .total p.puntaje {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0px;
}

.detalles-investigacion .puntuacion .total p.texto {
    text-align: center;
}
.detalles-investigacion .alert {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
}
.table-content .tbody p.cortar-texto.jurados {
    display: flex;
    flex-direction: column;
}

.table-content .tbody p.cortar-texto.jurados > span {
    display: block;
    padding: 5px 10px;
    border-bottom: 1px solid black;
    white-space: break-spaces;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
}
.acciones.investigaciones .alert.acciones {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
}
.detalles-investigacion .puntuacion .evaluadores h4 {
    text-align: center;
}

.detalles-investigacion .puntuacion .evaluadores {
    border-top: 1px solid black;
    padding-top: 20px;
}

.detalles-investigacion .puntuacion .evaluadores p {
    text-align: center;
}
.detalles-investigacion .evaluaciones  p.titleEvaluaciones {
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0px;
}
.detalles-investigacion .evaluaciones .evaluacion {
    border-bottom: 1px solid black;
    margin: 10px 0px;
    padding: 10px 0px;
}

.detalles-investigacion .evaluaciones .evaluacion:last-child {
    border-bottom: none;
}

.detalles-investigacion .evaluaciones .evaluacion .body p {
    margin-bottom: 2px;
}